import { createSelector } from 'reselect';
import { prop } from '../../utilities';

const authorizationDomain = state => state.authorization;

const generalDomainSelector = state => state.general;

const authRegisterDomain = createSelector(
  authorizationDomain,
  auth => auth.register,
);

const registrationDomain = createSelector(
  authorizationDomain,
  authorization => authorization.register,
);

export const registrationErrorMessageSelector = createSelector(
  registrationDomain,
  register => register.error,
);

export const registrationSuccessMessageSelector = createSelector(
  registrationDomain,
  register => register.success,
);

export const registrationIsFetchingSelector = createSelector(
  authRegisterDomain,
  register => register.isFetching,
);

export const dimmerVisibilitySelector = createSelector(
  generalDomainSelector,
  general => general.dimmerVisible || false,
);

const orderDomainSelector = state => state.orders;

export const createdOrderIsFetchingSelector = createSelector(
  orderDomainSelector,
  substate => prop(substate, 'createdOrder.isFetching', true),
);

export const createdOrderDataSelector = createSelector(
  orderDomainSelector,
  substate => prop(substate, 'createdOrder.createdOrderData', null),
);

export const langSelector = createSelector(generalDomainSelector, substate =>
  prop(substate, 'lang', ''),
);

export const currencySelector = createSelector(
  generalDomainSelector,
  substate => prop(substate, 'currency', ''),
);

export const loginModalVisibleSelector = createSelector(
  generalDomainSelector,
  substate => prop(substate, 'loginModalVisible', false),
);

const authDomainSelector = state => state.auth || {};

export const userSelector = createSelector(
  authDomainSelector,
  substate => substate.user,
);

export const contactInfoSelector = createSelector(
  generalDomainSelector,
  substate =>
    prop(substate, 'contactInfo', { contactEmail: '', contactTel: '' }),
);

export const invoiceDataSelector = createSelector(
  generalDomainSelector,
  substate => prop(substate, 'invoiceData', {}),
);

export const controlInstituteDataSelector = createSelector(
  generalDomainSelector,
  substate => prop(substate, 'controlInstituteData', {}),
);

export const pointsAmountRatioSelector = createSelector(
  generalDomainSelector,
  substate => prop(substate, 'pointsAmountRatio', 0),
);

export const generalFreeDeliveryInfoSelector = createSelector(
  generalDomainSelector,
  substate => prop(substate, 'freeDeliveryInfo', {}),
);

export const topMenuItemsSelector = createSelector(
  generalDomainSelector,
  substate => prop(substate, 'menuItems.topMenuItems', []),
);

export const topMenuItemsIsFetchingSelector = createSelector(
  generalDomainSelector,
  substate => prop(substate, 'menuItems.isFetching', false),
);

export const topMenuBrandsSelector = createSelector(
  generalDomainSelector,
  substate => prop(substate, 'brands', []),
);

export const searchResultsSelector = createSelector(
  generalDomainSelector,
  substate =>
    prop(substate, 'search', { isFetching: false, results: { articles: [] } }),
);

export const searchResultsOffsetSelector = createSelector(
  generalDomainSelector,
  substate => prop(substate, 'search.offset', 0),
);

export const partialSearchResultsSelector = createSelector(
  generalDomainSelector,
  substate =>
    prop(substate, 'search.partialResults', {
      isFetching: false,
      results: { articles: [] },
    }),
);
